import { render, staticRenderFns } from "./LogisticInfoBox.vue?vue&type=template&id=1ee3f819&scoped=true&"
import script from "./LogisticInfoBox.vue?vue&type=script&lang=js&"
export * from "./LogisticInfoBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee3f819",
  null
  
)

export default component.exports