<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="600px"
  >
    <div class="p-a-10 height-full flex-column box-sizing">
      <div class="flex1">
        <el-row>
          <el-col :span="6" class="text-right">
            <span>订单号：</span>
          </el-col>
          <el-col :span="18">
            <span>{{ payInfo.multiOrders || payInfo.orderCode }}</span>
          </el-col>
        </el-row>
        <el-row class="m-t-10">
          <el-col :span="6" class="text-right">
            <span>支付单号：</span>
          </el-col>
          <el-col :span="18">
            <span>{{ payInfo.payCode }}</span>
          </el-col>
        </el-row>
        <el-row class="m-t-10">
          <el-col :span="6" class="text-right">
            <span>支付金额：</span>
          </el-col>
          <el-col :span="18">
            <span class="font-size-16 font-weight-bold text-danger">￥{{ payInfo.totalAmount }}</span>
          </el-col>
        </el-row>
        <el-row class="m-t-10">
          <el-col :span="6" class="text-right">
            <span>提交时间：</span>
          </el-col>
          <el-col :span="18">
            <span>{{ payInfo.createdAt }}</span>
          </el-col>
        </el-row>
        <el-row class="m-t-10">
          <el-col :span="6" class="text-right">
            <span>支付上传凭证：</span>
          </el-col>
          <el-col :span="18">
            <div class="flex-1 flex" v-if="payInfo.voucherImages && payInfo.voucherImages.length">
              <div v-for="(img, index) in payInfo.voucherImages" :key="index">
                <el-image
                  ref="elImage"
                  class="order_img m-r-5"
                  fit="cover"
                  :src="img"
                  :preview-src-list="payInfo.voucherImages"
                >
                </el-image>
              </div>
            </div>
            <div v-else>
              <span class="text-999">无上传凭证</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="m-t-10">
          <el-col :span="6" class="text-right">
            <span>买家备注：</span>
          </el-col>
          <el-col :span="18">
            <span>{{ payInfo.buyerRemark }}</span>
          </el-col>
        </el-row>
        <el-row class="m-t-10" v-if="['待付款-审核不通过'].includes(payInfo.statusName)">
          <el-col :span="6" class="text-right">
            <span>审核备注：</span>
          </el-col>
          <el-col :span="18">
            <span class="text-danger">{{ payInfo.remark }}</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" class="width-full" @click="closedFn">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "PayInfoBox",
  props: {
    curRow: {
      type: Object,
      default: () => {},
      require: true
    }
  },
  data() {
    return {
      title: "支付信息",
      payInfo: {
        multiOrders: "DH202404221602440151, DH202404221602440151",
        orderCode: "DH202404221602440151",
        payCode: "2021214514511444714",
        totalAmount: "12",
        createdAt: "2021-12-30 14:25:30",
        voucherImages: [
          "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
        ],
        buyerRemark: "备注内容备注内容备注内容备注内容备注内容备注内  容备  注内容备注内容",
        remark: "少给500元，请重新支付！",
        statusName: "待付款（卖家审核不通过）"
      }
    };
  },
  watch: {
    "$attrs.visible"(val) {
      if (val) {
        this.payInfo = { ...this.curRow };
      }
    }
  },
  methods: {
    closedFn() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped>
.order_img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
</style>
