<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="600px"
  >
    <div>
      <div class="flex">
        <img :src="dataObj.companyLogo" alt="" class="company-logo" />
        <div class="m-l-20 flex-column flex-around">
          <div>{{ dataObj.name }}</div>
          <div>地址：{{ dataObj.address }}</div>
          <div>联系电话：{{ dataObj.phone }}</div>
        </div>
      </div>
      <el-divider></el-divider>
      <el-form
        :model="ruleForm"
        :rules="rules"
        :show-message="false"
        ref="appoint"
        size="small"
        label-width="100px"
        class="ruleForm m-tb-20"
      >
        <el-form-item label="选择车辆" prop="car">
          <el-input
            v-model.trim="ruleForm.car"
            type="text"
            maxlength="20"
            clearable
            placeholder="请输入想要搜索关键字"
          />
        </el-form-item>
        <el-form-item label="业务类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择" clearable>
            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约日期" prop="appointDate">
          <el-date-picker
            style="width: 100%;"
            v-model="ruleForm.appointDate"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="问题描述" prop="desc">
          <el-input
            type="textarea"
            placeholder="请描述您爱车的问题或您对修理厂的备注"
            v-model="ruleForm.desc"
            :autosize="{ minRows: 6, maxRows: 6 }"
            show-word-limit
            clearable
            maxlength="200"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="text-center">
      <!-- <el-button plain size="small" @click="closedFn">取消</el-button> -->
      <el-button type="primary" size="small" style="width:90%" @click="onSure">
        {{ btnText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "AppointBox",
  props: {
    curRow: Object
  },
  data() {
    return {
      title: "在线预约/修改预约",
      dataObj: {
        companyLogo: require("@/assets/img/setting-not.jpg"),
        name: "卡成都武侯区奥迪奔驰4S维修店",
        address: "四川省成都市高新区天府四街长虹科技大厦B座901",
        phone: "028-08891789"
      },
      ruleForm: {},
      rules: {
        car: [{ required: true, message: "请选择车辆", trigger: "change" }],
        type: [{ required: true, message: "请选择业务类型", trigger: "change" }],
        appointDate: [{ type: "date", required: true, message: "请选择业务类型", trigger: "change" }]
      },
      typeOptions: [
        { id: "维修", name: "维修" },
        { id: "保养", name: "保养" },
        { id: "美容", name: "美容" }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  },
  computed: {
    btnText() {
      const flag = this.ruleForm.type || this.ruleForm.appointDate;
      const dateStr = this.ruleForm.appointDate ? this.$dayjs(this.ruleForm.appointDate).format("MM月DD日 ") : "";
      const weekdayIndex = this.ruleForm.appointDate ? this.$dayjs(this.ruleForm.appointDate).format("d") : "";
      let weekdayStr = "";
      if (this.ruleForm.appointDate) {
        switch (weekdayIndex) {
          case "0":
            weekdayStr = "周日 ";
            break;
          case "1":
            weekdayStr = "周一 ";
            break;
          case "2":
            weekdayStr = "周二 ";
            break;
          case "3":
            weekdayStr = "周三 ";
            break;
          case "4":
            weekdayStr = "周四 ";
            break;
          case "5":
            weekdayStr = "周五 ";
            break;
          case "6":
            weekdayStr = "周六 ";
            break;
        }
      }

      const typeStr = this.ruleForm.type ? this.ruleForm.type : "";
      const str = `${flag ? dateStr + weekdayStr + typeStr + " | " : ""}提交预约/确定修改`;

      return str;
    }
  },
  watch: {
    "$attrs.visible": {
      handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },
  methods: {
    initData() {},
    closedFn() {
      this.$emit("update:visible", false);
    },
    onSure() {
      this.$refs.appoint.validate((valid, err) => {
        if (valid) {
          const params = {
            ...this.dataObj,
            ...this.ruleForm,
            appointDate: this.$dayjs(this.ruleForm.appointDate).format("YYYY-MM-DD")
          };

          console.log("params", params);
          this.closedFn();
        } else {
          const errTips = err[Object.keys(err)[0]][0].message;
          this.$message.warning({ message: errTips, showClose: true });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.company-logo {
  width: 220px;
  height: 130px;
  background: #f2f2f2;
  border-radius: 4px;
}
</style>
