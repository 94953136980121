<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="1300px"
  >
    <div>
      <el-table
        ref="cancelOrderTable"
        class="table-base m-t-10"
        size="small"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        height="400px"
      >
        <el-table-column prop="date" label="发货日期" align="center" min-width="100"> </el-table-column>
        <el-table-column prop="freightNo" label="货物单号" align="center" show-overflow-tooltip min-width="150">
        </el-table-column>
        <el-table-column
          prop="logisticsCompanyName"
          label="物流公司"
          align="center"
          show-overflow-tooltip
          min-width="100"
        >
        </el-table-column>
        <el-table-column prop="qty" label="发货件数" align="center" min-width="100"> </el-table-column>
        <el-table-column prop="packMethod" label="包装方式" align="center" min-width="100"> </el-table-column>
        <el-table-column prop="transportMethod" label="运输方式" align="center" min-width="100"> </el-table-column>
        <el-table-column prop="payerName" label="付款方" align="center" min-width="100"> </el-table-column>
        <el-table-column prop="subject" label="发货主题" align="center" show-overflow-tooltip min-width="100">
        </el-table-column>
        <el-table-column prop="remark" label="发货备注" align="center" show-overflow-tooltip min-width="100">
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="text-center">
      <!-- <el-button plain size="small" @click="closedFn">取消</el-button> -->
      <el-button type="primary" size="small" @click="onSure">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getLogisticsErpByCode } from "@/api/order";

export default {
  name: "LogisticInfoBox",
  props: {
    curRow: Object
  },
  data() {
    return {
      title: "物流信息",
      loading: false,
      tableData: [
        // {
        //   date: "2023-09-23",
        //   freightNo: "2374623682734",
        //   logisticsCompanyName: "中通物流公司",
        //   qty: "1",
        //   packMethod: "粗略包装",
        //   transportMethod: "空运",
        //   payerName: "发货方垫付",
        //   subject: "XXXXX",
        //   remark: "XXXXX"
        // }
      ],
      pageInfo: {
        page: 1,
        totalSize: 0,
        pageSize: 20
      }
    };
  },
  watch: {
    "$attrs.visible": {
      handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },
  methods: {
    initData() {
      let params = {
        orderCode: this.curRow.orderCode
      };
      this.tableData = [];
      this.loading = true;
      getLogisticsErpByCode(params)
        .then(res => {
          this.loading = false;

          this.tableData = res || [];
        })
        .catch(err => {
          this.loading = false;

          this.$message.error(err?.message);
        });
    },
    closedFn() {
      this.$emit("update:visible", false);
    },
    onSure() {
      this.closedFn();
    }
  }
};
</script>

<style lang="less" scoped></style>
