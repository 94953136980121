<template>
  <el-drawer
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :wrapperClosable="false"
    :with-header="false"
    append-to-body
    size="25%"
    class="drawer"
  >
    <div class="p-tb-10 p-lr-10 height-full flex-column box-sizing">
      <div class="flex flex-row-center">
        <span class="font-size-16 font-weight-bold">订单日志：</span>
        <span>{{ curRow.orderCode }}</span>
      </div>
      <div class="flex1 overflow-auto m-t-20">
        <el-timeline v-if="logsList.length">
          <el-timeline-item
            v-for="(logs, index) in logsList"
            :key="index"
            type="primary"
            :timestamp="renderTime(logs.time)"
            placement="top"
          >
            {{ logs.content }}
          </el-timeline-item>
        </el-timeline>
        <div class="text-center text-999" v-else>暂无数据</div>
      </div>
    </div>
    <div class="absolute bg-primary text-white" @click="closeFn">
      <i class="el-icon-d-arrow-right font-size-16 font-weight-bold"></i>
    </div>
  </el-drawer>
</template>

<script>
import { getOrderLogs } from "@/api/order";
export default {
  name: "OrderLogBox",
  props: {
    curRow: {
      type: Object,
      default: () => {},
      require: true
    }
  },
  data() {
    return {
      title: "订单日志",
      logsList: []
    };
  },
  watch: {
    "$attrs.visible"(val) {
      if (val) {
        this.getLogs();
      }
    }
  },
  methods: {
    getLogs() {
      getOrderLogs({ id: this.curRow.id })
        .then(res => {
          this.logsList = res || [];
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    renderTime(time) {
      return this.$dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    closeFn() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped>
.drawer {
  /deep/.el-drawer__body {
    height: 100%;
    position: relative;
    .absolute {
      position: absolute;
      left: 0;
      top: 50%;
      padding: 14px 2px;
      border-radius: 0 6px 6px 0;
      cursor: pointer;
    }
  }
  /deep/.el-timeline-item__tail {
    border-left: 2px solid #6589f8;
  }
}
</style>
