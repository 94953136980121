<template>
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    append-to-body
    @close="closedFn"
    width="1200px"
  >
    <div>
      <div>
        <el-input
          class="m-r-10"
          style="width:300px"
          placeholder="商品名称/编码/规格/车型/生产码等关键字"
          size="small"
          v-model="keyword"
          clearable
        ></el-input>
        <el-button type="primary" size="small" @click="searchFn">查询</el-button>
        <el-button size="small" @click="resetFn">重置</el-button>
      </div>
      <el-table
        ref="cancelOrderTable"
        class="table-base m-t-10"
        size="small"
        v-loading="loading"
        :data="tableData"
        border
        stripe
        height="400px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed type="selection" align="center" width="55"> </el-table-column>
        <el-table-column prop="name" label="商品名称" align="center" show-overflow-tooltip min-width="200">
        </el-table-column>
        <el-table-column prop="warehouse" label="仓库" align="center" show-overflow-tooltip min-width="100">
        </el-table-column>
        <el-table-column prop="qty" label="订货总数" align="center" min-width="90"> </el-table-column>
        <el-table-column prop="termQty" label="已取消数" align="center" min-width="90"> </el-table-column>
        <el-table-column prop="outQty" label="已发货数" align="center" min-width="90"> </el-table-column>
        <el-table-column prop="waitOutQty" label="未发货数" align="center" min-width="90"> </el-table-column>
        <el-table-column prop="receiveQty" label="已收货数" align="center" min-width="90"> </el-table-column>
        <el-table-column prop="receivableQty" label="可收货数" align="center" min-width="90"> </el-table-column>
        <el-table-column prop="receiveNum" label="收货数" align="center" min-width="150">
          <template slot-scope="scope">
            <div class="flex flex-row-center">
              <el-input-number
                size="mini"
                v-model="scope.row.receiveNum"
                :min="1"
                :max="Number(scope.row.receivableQty)"
              ></el-input-number>
              <span class="m-l-10">{{ scope.row.unitName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="sureReceiptFn(scope.row)">
              确认收货
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page_footer">
        <div>
          <!-- <span>已选中 {{ selectLen }} 个配件</span>
          <span class="m-lr-8">|</span>
          <span>批量操作</span>
          <el-button size="small" type="primary" class="m-l-8" @click="allReceiptFn">
            全部收货
          </el-button> -->
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageInfo.pageSize"
          layout="total, prev, pager, next, jumper, sizes"
          :total="pageInfo.totalSize"
        >
        </el-pagination>
      </div>
      <div class="text-center m-t-20 text-666">为了保证你的售后权益，请收到商品确认无误后再确认收货</div>
    </div>
    <div slot="footer" class="text-center">
      <el-button type="primary" size="small" @click="allReceiptFn">全部收货</el-button>
      <el-button type="primary" size="small" @click="selectedReceiptFn">选中收货</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { postOrdersToReceiveList, postOrdersBatchReceive } from "@/api/order";

export default {
  name: "ConfirmReceiptBox",
  props: {
    ids: {
      type: Array,
      default: () => []
    },
    query: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      title: "确认收货",
      keyword: "",
      loading: false,
      tableData: [
        // {
        //   id: 10,
        //   orderId: 12,
        //   name: "【集成电路/数据处理及控制（汽车音响）】8542310000奥迪完好奥迪", //配件名称
        //   warehouse: "北京总仓", //发货仓库
        //   qty: "10", //订货总数
        //   termQty: "0", //取消货数
        //   outQty: "10", //已发货数
        //   waitOutQty: "0", //未发货数
        //   receiveQty: "1", //已收货数
        //   receivableQty: "6", //可收货数
        //   receiveNum: "6", //收货数
        //   unitName: "件"
        // }
      ],
      pageInfo: {
        page: 1,
        totalSize: 0,
        pageSize: 20
      },
      selectionData: []
      // currentRow: {},
      // logisticInfoVisible: false
    };
  },
  computed: {
    selectLen() {
      //选中条数
      return this.selectionData.length;
    }
  },
  watch: {
    "$attrs.visible": {
      handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },

  methods: {
    searchFn() {
      this.pageInfo.page = 1;
      this.initData();
    },
    resetFn() {
      this.keyword = "";
    },
    initData() {
      let params = {
        keyword: this.keyword,
        ...this.pageInfo
      };
      if (this.ids.length) {
        params.ids = this.ids;
      } else {
        params.query = this.query;
      }
      this.loading = true;
      postOrdersToReceiveList(params)
        .then(res => {
          this.loading = false;
          this.tableData = res.rows?.map(item => ({ ...item, receiveNum: Number(item.receivableQty) })) || [];
          this.pageInfo.totalSize = res.totalSize || 0;
        })
        .catch(err => {
          this.loading = false;

          this.$message.error(err?.message);
        });
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    closedFn() {
      this.$emit("update:visible", false);
    },
    //全部收货
    allReceiptFn() {
      let params = {
        query: {
          ...this.query
        }
      };
      this.postOrdersBatchReceiveFn(params, "全部收货成功！");
    },
    //选中收货
    selectedReceiptFn() {
      if (!this.selectionData.length) {
        this.$message.warning("请选择要收货的商品");
        return;
      }
      let data = [];
      for (const item of this.selectionData) {
        const arrId = data.map(item => item.id);
        if (arrId.includes(item.orderId)) {
          for (const items of data) {
            if (items.id === item.orderId) {
              items.details.push({ id: item.id, receiveQty: item.receiveNum + "" });
              break;
            }
          }
        } else {
          data.push({
            id: item.orderId,
            details: [{ id: item.id, receiveQty: item.receiveNum + "" }]
          });
        }
      }
      let params = {
        data
      };

      this.postOrdersBatchReceiveFn(params, "收货成功！");
    },
    postOrdersBatchReceiveFn(params, text) {
      postOrdersBatchReceive(params)
        .then(() => {
          this.$message.success(text);
          this.closedFn();
          this.$emit("refresh");
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err?.message);
        });
    },
    //确认收货
    sureReceiptFn(row) {
      let obj = {
        id: row.orderId,
        details: [
          {
            id: row.id,
            receiveQty: row.receiveNum + ""
          }
        ]
      };
      let params = {
        data: [obj]
      };

      postOrdersBatchReceive(params)
        .then(() => {
          this.$message.success("收货成功！");
          this.pageInfo.page = 1;
          this.initData();
          this.$emit("refresh");
        })
        .catch(err => {
          this.loading = false;

          this.$message.error(err?.message);
        });
    }
  }
};
</script>
